import $ from 'jquery';

function infiniteScroll()
{
  // Infinite scroll
  $(window).scroll(function () { 
    if ($(window).scrollTop() >= $(document).height() - $(window).height() - 10) {
      loadPosts();
    }
  });

  // Infinite scroll button fallback
  $('body').on('click', '#load-older-posts', function(e) {
    // prevent new page load
    e.preventDefault();
      
    loadPosts();
  });

  function loadPosts() {
    var $olderPostsContainer = $('#load-older-posts');
    var $olderPostsBtn = $('#load-older-posts .btn');

    // store next page number
    var next_page = $olderPostsBtn.attr('href');
      
    // stop if no page found
    if (!next_page) {
      return;
    }

    // remove older posts button from DOM
    $olderPostsContainer.remove();

    // ajax older posts below existing posts
    var html;
    var $page;
    $.ajax({
      url: next_page,
      dataType: 'html',
      success: function (data) {
        html = $(data);
        $page = $('.page', html);

        $('#main-content').append($page);

        if ( !$('#load-older-posts .btn', $page).attr('href') ) {
          $(window).off('scroll');
        }
      },
    });
  }
}

export { infiniteScroll };