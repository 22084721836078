// Vendors
import $ from 'jquery';
import 'bootstrap';
import Glide from '@glidejs/glide';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { infiniteScroll } from './helpers/infiniteScroll';
import ArrowDisabler from './helpers/arrowDisabler';


// Font awesome
library.add(fas,fab);
// https://fontawesome.com/how-to-use/with-the-api/methods/dom-i2svg
dom.i2svg();

// Sliders
var sliders = document.querySelectorAll('.glide');

for (var i = 0; i < sliders.length; i++) {
  var glide = new Glide(sliders[i], {
    perView: 3,
    // type: 'carousel',
    gap: 40,
    rewind: false,
    bound: true,
    swipeThreshold: false,
    dragThreshold: false,
    breakpoints: {
      900: {
        perView: 1,
        swipeThreshold: 80,
        dragThreshold: 120,
      }
    }
  });

  glide.mount({ ArrowDisabler });
}

var sliders = document.querySelectorAll('.photo-glide');

for (var i = 0; i < sliders.length; i++) {
  var galery = new Glide(sliders[i], {
    type: 'slider',
    rewind: false,
    perView: 4,
    gap: 10,
    breakpoints: {
      650: {
        perView: 3,
        focusAt: 'center'
      }
    }
  });

  galery.on('run.after', function(e) {
    $('.current-picture').attr('src', $('.glide__slide--active .nav-picture').attr('src'));
    $('.gallery-count .current').text($('.glide__slide--active .nav-picture').attr('data-index'));
  })
  
  galery.mount({ ArrowDisabler });
}

$(document).ready(function() {
  if ($('body').hasClass('blog') || $('body').hasClass('archive') || $('body').hasClass('search-results')) {
    infiniteScroll();
  }

  //Position footer to bottom if content too small
  if ($('body').height() < $(window).height() - 100) {
    $('.site-footer').addClass('stick-to-bottom');
  }

  $( window ).resize(function() {
    if ($('body').height() < $(window).height() - 100) {
      $('.site-footer').addClass('stick-to-bottom');
    } else {
      $('.site-footer').removeClass('stick-to-bottom');
    }
  });

  // Toggle mobile menu
  $('.navbar-burger').on('click', () => $('.navbar-burger, .navbar-menu').toggleClass('is-active'));

  // Toggle quick search form
  $('.search-form').on('click', 'button', function(e) {
    if ( $(window).outerWidth() >= 992) {
      let $form = $(this).closest('.search-form');
      if (!$form.hasClass('active')) {
        e.preventDefault();
        $form.addClass('active');
      }
    }
  });

  $('.search-form').on('blur', 'input', function() {
    $(this).closest('.search-form').removeClass('active');
  });

  $(window).on('click', function(e) {
    if (!$(e.target).hasClass('form-control') && !$(e.target).hasClass('search-icon')) {
      $('.search-form').removeClass('active');
    }
  });

  // Single video article slider
  $('.current-picture').attr('src', $('.glide__slide--active .nav-picture').attr('src'));

  $('.photo-glide').on('click', '.nav-picture', function() {
    let index = $(this).attr('data-index') - 1;
    galery.go('=' + index);
  });
});
